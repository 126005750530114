import {
  GLTFFileLoader,
  GLTFLoaderAnimationStartMode,
} from '@babylonjs/loaders';
import {
  ISceneLoaderAsyncResult,
  ISceneLoaderPlugin,
  Observer,
  Scene,
  SceneLoader,
  Texture,
} from '@babylonjs/core';

import { ModelConfig } from './types';

// Выполнен ли патч GLTFLoader
let gltfLoaderPatcher: Observer<ISceneLoaderPlugin> | null;

/**
 * Выполнить патч GLTFLoader
 *
 * Отключить воспроизведение первой анимации при загрузке меша
 */
function patchGLTFLoader() {
  if (gltfLoaderPatcher) return;

  gltfLoaderPatcher = SceneLoader.OnPluginActivatedObservable.add((p) => {
    const plugin = p;
    if (plugin.name === 'gltf' && plugin instanceof GLTFFileLoader) {
      // Do not play the first animation after loading
      plugin.animationStartMode = GLTFLoaderAnimationStartMode.NONE;
    }
  });
}

/**
 * Асинхронная загрузка меша
 */
export function loadMesh(
  scene: Scene,
  cfg: ModelConfig
): Promise<ISceneLoaderAsyncResult> {
  patchGLTFLoader();

  return SceneLoader.ImportMeshAsync(null, cfg.root_url, cfg.filename, scene);
}

/**
 * Найти и вернуть элемент с именем name в массиве элементов
 *
 * В случае неудачи будет выброшено исключение Error
 * @param elements массив элементов для поиска
 * @param name имя элемента, который необходимо найти
 * @returns Искомый элемент с заданным именем
 */
export function findByName<T extends { name: string }>(
  elements: T[],
  name: string
): T {
  const targetMesh = elements.find((el) => el.name === name);
  if (!targetMesh) throw Error(`Element with name '${name}' not found`);
  return targetMesh;
}

/**
 * Асинхронная загрузка текстуры
 * @param url defines the url of the picture to load as a texture
 * @param scene defines the scene the texture will belong to
 * @param noMipmap defines if the texture will require mip maps or not
 * @param invertY defines if the texture needs to be inverted on the y axis during loading
 * @param samplingMode defines the sampling mode we want for the texture while fectching from it (Texture.NEAREST_SAMPLINGMODE...)
 */
export function loadTexture(
  url: string,
  scene: Scene,
  noMipmap?: boolean,
  invertY?: boolean,
  samplingMode?: number
): Promise<Texture> {
  return new Promise<Texture>((resolve, reject) => {
    const res = new Texture(
      url,
      scene,
      noMipmap,
      invertY,
      samplingMode,
      () => {
        resolve(res);
      },
      (msg) => {
        reject(msg);
      }
    );
  });
}

export function loadJSImage(url: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const res = new window.Image();
    res.crossOrigin = 'anonymous'; // FIXME: у репозитория неправильно настроен CORS
    res.onload = () => {
      resolve(res);
    };
    res.onerror = (e) => {
      reject(e);
    };
    res.src = url;
  });
}
