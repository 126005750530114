import {
  AbstractMesh,
  AnimationGroup,
  ISceneLoaderAsyncResult,
  Mesh,
  PBRMaterial,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../../common/utils';

import { KIPConfig } from '../types';

const nameComp = (a: { name: string }, b: { name: string }) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export type KIPDoorModel = {
  mesh: Mesh;
  lockMesh: TransformNode;
  openAnim: AnimationGroup;
};

export type KIPWireModel = {
  mesh: AbstractMesh;
  defectiveAnim: AnimationGroup;
  properlyAnim: AnimationGroup;
};

export type KIPTerminalModel = {
  name: string;
  body: AbstractMesh;
  bbox: AbstractMesh;
  screw?: AbstractMesh;
  helper: TransformNode;
};

export type KIPTerminalsModel = {
  all: KIPTerminalModel[];
  measure: KIPTerminalModel[];
  power: KIPTerminalModel[];
};

export type KIPMaterialsModel = {
  normal: PBRMaterial;
  dirty: PBRMaterial;
};

export type KIPKeyHoleModel = {
  main: AbstractMesh;
  bbox: AbstractMesh;
  helper: TransformNode;
};

export interface IKIPModel {
  get main(): AbstractMesh;

  get backDoor(): KIPDoorModel;

  get frontDoor(): KIPDoorModel;

  get t1Wire(): KIPWireModel;

  get terminals(): KIPTerminalsModel;

  get keyHoleFront(): KIPKeyHoleModel;

  get labelsBoxes(): AbstractMesh[];

  get materials(): KIPMaterialsModel;
}

export class KIPModel implements IKIPModel {
  private _allMeshes: AbstractMesh[];

  private _root: AbstractMesh;

  private _main: AbstractMesh;
  private _backDoor: KIPDoorModel;
  private _frontDoor: KIPDoorModel;
  private _terminals: KIPTerminalsModel;
  private _labelsBoxes: AbstractMesh[];
  private _keyHoleFront: KIPKeyHoleModel;
  private _keyHoleBack: KIPKeyHoleModel;
  private _t1Wire: KIPWireModel;
  private _materials: KIPMaterialsModel;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    const { meshes, transformNodes } = model;
    this._allMeshes = meshes;

    [this._root] = meshes;
    this._main = findByName(meshes, 'KIP');
    this._backDoor = {
      mesh: findByName(meshes, 'Backdoor_primitive0') as Mesh,
      lockMesh: findByName(transformNodes, 'LockBack'),
      openAnim: findByName(model.animationGroups, 'OpensBackDoor'),
    };
    this._frontDoor = {
      mesh: findByName(meshes, 'Frontdoor_primitive0') as Mesh,
      lockMesh: findByName(transformNodes, 'LockFront'),
      openAnim: findByName(model.animationGroups, 'OpensFrontDoor'),
    };
    this._t1Wire = {
      mesh: findByName(meshes, 'Wires_T1_primitive1') as Mesh,
      defectiveAnim: findByName(model.animationGroups, 'Wire_Defective'),
      properlyAnim: findByName(model.animationGroups, 'Wire_Properly'),
    };

    const keyHoleBack = findByName(meshes, 'Box_Keyhole_Back');
    const keyHoleFront = findByName(meshes, 'Box_Keyhole_Front');

    this._keyHoleBack = {
      main: keyHoleBack,
      bbox: keyHoleBack,
      helper: keyHoleBack,
    };

    this._keyHoleFront = {
      main: keyHoleFront,
      bbox: keyHoleFront,
      helper: keyHoleFront,
    };

    const terminals = meshes
      .filter((mesh) => mesh.name.startsWith('Terminal_'))
      .sort(nameComp);
    const terminalsScrew = meshes
      .filter((mesh) => mesh.name.startsWith('Screw'))
      .sort(nameComp);
    const terminalsHelpers = transformNodes
      .filter((mesh) => mesh.name.startsWith('Helper_Terminal_'))
      .sort(nameComp);

    const all: KIPTerminalModel[] = [];
    terminals.forEach((body, id) => {
      all.push({
        name: body.name,
        body,
        bbox: body,
        screw: terminalsScrew[id],
        helper: terminalsHelpers[id] || body,
      });
    });

    this._terminals = {
      all,
      measure: all.filter((t) => !t.body.name.endsWith('PT')),
      power: all.filter((t) => t.body.name.endsWith('PT')),
    };
    this._labelsBoxes = meshes.filter((mesh) =>
      mesh.name.startsWith('BoundingBox_')
    );

    const normalMaterial = this._main.material;
    const dirtMaterial = this._backDoor.mesh.material; // материал DirtAndScratches привязан к задней двери
    this._backDoor.mesh.material = normalMaterial;
    // Для большей безопасности проверяем что найдено два разных материала
    if (normalMaterial === dirtMaterial)
      throw Error('Probably DirtAndScratches material not found');

    this._materials = {
      normal: normalMaterial as PBRMaterial,
      dirty: dirtMaterial as PBRMaterial,
    };
  }

  static async load(cfg: KIPConfig, scene: Scene): Promise<KIPModel> {
    const res = await loadMesh(scene, cfg.model);
    return new KIPModel(scene, res);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): TransformNode {
    return this._root;
  }

  get main(): AbstractMesh {
    return this._main;
  }

  get backDoor(): KIPDoorModel {
    return this._backDoor;
  }

  get frontDoor(): KIPDoorModel {
    return this._frontDoor;
  }

  get t1Wire(): KIPWireModel {
    return this._t1Wire;
  }

  get terminals(): KIPTerminalsModel {
    return this._terminals;
  }

  get keyHoleFront(): KIPKeyHoleModel {
    return this._keyHoleFront;
  }

  get labelsBoxes(): AbstractMesh[] {
    return this._labelsBoxes;
  }

  get materials(): KIPMaterialsModel {
    return this._materials;
  }
}
