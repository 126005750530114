import { autorun } from 'mobx';

import { AdvancedDynamicTexture } from '@babylonjs/gui';
import { Scene, StandardMaterial, Texture } from '@babylonjs/core';

import { BaseModelController } from '../../../common/base';

import { IPP1Config, IPP1DisplayState } from '../types';

import { IPP1DisplayStore } from '../store/display';
import { IPP1Model } from '../model';
import { IPP1ScreenGUI } from '../gui';

export class IPP1DisplayController extends BaseModelController<
  IPP1Model,
  IPP1DisplayStore,
  IPP1Config
> {
  private static DISPLAY_WIDTH = 217;
  private static DISPLAY_HEIGHT = 78;
  private static TEX_Q = 2;

  private _gui: IPP1ScreenGUI;
  private _texture: AdvancedDynamicTexture;

  constructor(
    scene: Scene,
    model: IPP1Model,
    store: IPP1DisplayStore,
    cfg: IPP1Config,
    UIImages: HTMLImageElement[]
  ) {
    super(scene, model, store, cfg);
    this._texture = new AdvancedDynamicTexture(
      'IPP1_Display',
      IPP1DisplayController.DISPLAY_WIDTH * IPP1DisplayController.TEX_Q,
      IPP1DisplayController.DISPLAY_HEIGHT * IPP1DisplayController.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );

    const screenMat = new StandardMaterial('IPP1Display_material', scene);
    screenMat.backFaceCulling = false;
    screenMat.diffuseTexture = this._texture;
    screenMat.opacityTexture = this._texture;
    model.setDisplayMaterial(screenMat);

    this._gui = new IPP1ScreenGUI(this._texture, UIImages);
  }

  protected _connectToStore(store: IPP1DisplayStore): void {
    autorun(() => {
      this.setState(store.state);
    });
  }

  private setState(state: IPP1DisplayState): void {
    // TODO: Конвертация из enum в номер картинки. Лучше пока не трогать
    //  ни enum ни картинки, так как порядок значений в enum соответствует порядку картинок в массиве.
    //  А вообще надо это переделать.
    const imageIndex = Object.values(IPP1DisplayState).indexOf(state);
    this._gui.showImage(imageIndex);
  }
}
