import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { TabletConfig } from './types';

export class TabletModel {
  private _main: AbstractMesh;
  private _sheet: AbstractMesh;

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    this._main = findByName(model.meshes, 'Tablet');
    this._sheet = findByName(model.meshes, 'Sheet');
  }

  static async load(scene: Scene, cfg: TabletConfig): Promise<TabletModel> {
    const res = await loadMesh(scene, cfg.model);
    return new TabletModel(scene, res);
  }

  /**
   * Управлять глобальной видимостью объекта
   */
  setVisibility(isVisible: boolean): void {
    this._main.isVisible = isVisible;
    this._sheet.isVisible = isVisible;
  }

  /**
   * Получить корень, за который можно привязывать модель
   */
  get root(): TransformNode {
    return this._main;
  }

  get mainMesh(): AbstractMesh {
    return this._main;
  }

  get sheetMesh(): AbstractMesh {
    return this._sheet;
  }
}
