import { SceneBuilderComponents } from '../scene-components/init';

export const buildConfigs: SceneBuilderComponents = {
  core: {
    xr: {},
  },

  fullscreenPanel: true,

  player: {
    hands: {
      left: {
        model: {
          filename: 'HandLeft.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fplayer%2Fhands%2Fv01%2F',
        },
      },
      right: {
        model: {
          filename: 'HandRight.gltf',
          root_url:
            'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fplayer%2Fhands%2Fv01%2F',
        },
      },
    },
    inventory: {},
  },

  tools: {
    brush: {
      model: {
        filename: 'Brush.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fbrush%2Fv02%2F',
      },
    },
    ipp1: {
      model: {
        filename: 'IPP.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fipp1%2Fv02%2F',
      },
      ui: {
        images_root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fipp1%2Fv1%2Fui%2F',
      },
    },
    tablet: {
      model: {
        filename: 'Tablet.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Ftablet%2Fv02%2F',
      },
      ui: {
        images_root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Ftablet%2Fv1%2Fui%2F',
      },
      acceptVoltageWindow: {},
    },
    key: {
      model: {
        filename: 'Key.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fkey%2Fv02%2F',
      },
    },
    tube: {
      model: {
        filename: 'Tube.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Ftube%2Fv02%2F',
      },
    },
    wrench: {
      model: {
        filename: 'Wrench.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fwrench%2Fv02%2F',
      },
    },
  },

  scene: {
    acceptJumpersWindow: {
      image_url:
        'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fworld%2Fkip%2Fv02%2FKIP_connect_scheme.jpg',
    },
    EMCVE: {
      model: {
        filename: 'EMCVE.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Ftools%2Fipp1%2Fv1%2F',
      },
    },
    infoPanel: {
      place: 'kip_left',
    },
    KIP: {
      model: {
        filename: 'KIP.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fworld%2Fkip%2Fv03%2F',
      },
    },
    jumpers: {
      model: {
        filename: 'Jumper.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fworld%2Fjumpers%2F',
      },
    },
    resultPanel: true,
    toolsPanel: {
      ui: {
        images_root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fworld%2Ftools-panel%2Fv1%2Fui%2F',
      },
      place: 'kip_right',
    },
  },

  world: {
    landscape: {
      model: {
        filename: 'Landscape.gltf',
        root_url:
          'https://fs.devogip.ru/api/File/minio-dev?objectName=scene-components%2Fworld%2Flandscape%2Fv01%2F',
      },
    },
    lights: {
      lights: [
        {
          diraction: {
            x: -1,
            y: 1,
            z: 0.4,
          },
          intensity: 1.0,
        },
      ],
    },
    skybox: {
      skybox_url: 'https://fs.devogip.ru/api/File/minio-dev/skybox1_4k.jpg',
    },
  },
};
