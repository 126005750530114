/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import '@babylonjs/loaders/glTF';
import {
  AbstractMesh,
  Color3,
  ISceneLoaderAsyncResult,
  LinesMesh,
  Mesh,
  MeshBuilder,
  Scene,
  SceneLoader,
  Vector3,
  VertexBuffer,
  WebXRDefaultExperience,
} from '@babylonjs/core';
import { ResultsController } from 'api';

import {
  PreparedDataItemStepReportModel,
  PreparedDataItemUserStepReport,
} from '../types';

import { i18n } from '../../../libs';

/**
 * Загрузить мешь из определенной папки
 * @param scene Babylon сцена
 * @param rootPath Путь до папки с мешем и всеми остальными данными
 * @param fileName Имя файла с мешем
 * @returns Promise указанного меша
 */
export function loadMesh(
  scene: Scene,
  fileName: string,
  rootPath?: string
): Promise<ISceneLoaderAsyncResult> {
  const baseRootUrl = 'https://fs.devogip.ru/api/File/minio-dev';
  let rootUrl: string;
  if (!rootPath) {
    rootUrl = `${baseRootUrl}/`;
  } else {
    const rPath = rootPath.endsWith('/') ? rootPath : `${rootPath}/`;
    rootUrl = `${baseRootUrl}?objectName=${rPath.replaceAll('/', '%2F')}`;
  }
  return SceneLoader.ImportMeshAsync(null, rootUrl, fileName, scene);
}

/**
 * In-place random shuffle using unbiased Fisher-Yates (aka Knuth) Shuffle
 */
export function randomShuffle<T>(array: T[]): T[] {
  let m = array.length;

  while (m) {
    const i = Math.floor(Math.random() * m);
    m -= 1;

    const t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export const isRequiredStatesFulfilled = (
  requiredStates: any[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sceneState: any
): boolean => {
  return (
    !requiredStates ||
    requiredStates.every(
      ({
        stateName: requiredStateName,
        stateValue: requiredStateValue,
      }: {
        stateName: string;
        stateValue: any;
      }) => {
        const requiredState = sceneState[requiredStateName];
        // для сравнения массивов с зажатыми клеммами
        if (Array.isArray(requiredStateValue)) {
          return (
            Array.isArray(requiredState) &&
            requiredState.every((value, index) => {
              return value === requiredStateValue[index];
            })
          );
        }
        return requiredState === requiredStateValue;
      }
    )
  );
};

/**
 * Отрисовка локальных осей на заданном объекте
 */
export const localAxes = (
  size: number,
  parent: AbstractMesh,
  scene: Scene
): Mesh => {
  const pilotLocalAxisX = Mesh.CreateLines(
    'pilot_local_axisX',
    [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (Vector3.Zero as any)(),
      new Vector3(size, 0, 0),
      new Vector3(size * 0.95, 0.05 * size, 0),
      new Vector3(size, 0, 0),
      new Vector3(size * 0.95, -0.05 * size, 0),
    ],
    scene
  );
  pilotLocalAxisX.color = new Color3(1, 0, 0);
  const pilotLocalAxisY = Mesh.CreateLines(
    'pilot_local_axisY',
    [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (Vector3.Zero as any)(),
      new Vector3(0, size, 0),
      new Vector3(-0.05 * size, size * 0.95, 0),
      new Vector3(0, size, 0),
      new Vector3(0.05 * size, size * 0.95, 0),
    ],
    scene
  );
  pilotLocalAxisY.color = new Color3(0, 1, 0);
  const pilotLocalAxisZ = Mesh.CreateLines(
    'pilot_local_axisZ',
    [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (Vector3.Zero as any)(),
      new Vector3(0, 0, size),
      new Vector3(0, -0.05 * size, size * 0.95),
      new Vector3(0, 0, size),
      new Vector3(0, 0.05 * size, size * 0.95),
    ],
    scene
  );
  pilotLocalAxisZ.color = new Color3(0, 0, 1);
  const localOrigin = MeshBuilder.CreateBox('local_origin', { size: 1 }, scene);
  localOrigin.isVisible = false;
  pilotLocalAxisX.parent = localOrigin;
  pilotLocalAxisY.parent = localOrigin;
  pilotLocalAxisZ.parent = localOrigin;
  return localOrigin;
};

export const fetchLearnerReport = async (
  sessionId: string
): Promise<PreparedDataItemUserStepReport | null> => {
  try {
    const { data } = await ResultsController.apiResultsIdLessonstepreportGet(
      sessionId
    );
    const preparedData: PreparedDataItemUserStepReport = {
      ...data,
      fullName: [data.firstName, data.middleName, data.lastName]
        .filter(Boolean)
        .join(' '),
      lessonDurationFormatted: new Date(+data.lessonDuration!)
        .toUTCString()
        .slice(-12, -4),
      lessonTypeFormatted: i18n.t(`lessonTypes.${data.lessonType}`),
      stepsFormatted: data.steps!.map((step) => ({
        ...step,
        errorCountFormatted: `${step.errorCount}`,
        stepDurationFormatted: new Date(+step.stepDuration!)
          .toUTCString()
          .slice(-12, -4),
        key: step.number!,
      })),
    };
    preparedData.stepsFormatted.sort(
      (
        step1: PreparedDataItemStepReportModel,
        step2: PreparedDataItemStepReportModel
      ) => step1.number! - step2.number!
    );
    return preparedData;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export function showNormals(
  mesh: AbstractMesh,
  size: number,
  color?: Color3,
  scene?: Scene
): LinesMesh {
  const normals = mesh.getVerticesData(VertexBuffer.NormalKind)!;
  const positions = mesh.getVerticesData(VertexBuffer.PositionKind)!;
  const m = mesh.computeWorldMatrix(true);

  const sc = scene || mesh.getScene();
  color = color || Color3.Red();

  const lines = [];
  for (let i = 0; i < normals.length; i += 3) {
    const v1 = Vector3.TransformCoordinates(Vector3.FromArray(positions, i), m);
    const v2 = Vector3.TransformNormal(Vector3.FromArray(normals, i), m);
    lines.push([v1, v1.add(v2.scaleInPlace(size))]);
  }
  const normalLines = MeshBuilder.CreateLineSystem(
    'normalLines',
    { lines },
    sc
  );
  normalLines.color = color;
  return normalLines;
}
