import { AbstractMesh, TransformNode } from '@babylonjs/core';

import { ModelConfig } from '../../common/types';

import { IPP1SingleClipModel } from './model/single-clip';

export type IPP1Config = {
  model: ModelConfig;
  ui: {
    images_root_url: string;
  };
};

export enum IPP1Buttons {
  LEFT,
  UP,
  RIGHT,
  DOWN,
  ESC,
  ENTER,
  F1,
  F2,
  F3,
}

export enum IPP1DisplayState {
  MAIN_WORK = 'MAIN_WORK', // Главное меню [работа]
  MAIN_TESTER = 'MAIN_TESTER', // Главное меню [тестер]
  MAIN_ARCHIVE = 'MAIN_ARCHIVE', // Главное меню [архив]
  MAIN_CONNECTION = 'MAIN_CONNECTION', // Главное меню [связь]

  TESTER_VOLTMETERS = 'TESTER_VOLTMETERS', // Режим тестер [вольтметры]
  TESTER_POTENTIALS = 'TESTER_POTENTIALS', // Режим тестер [потенциалы]

  POTENTIALS_RESULT = 'POTENTIALS_RESULT', // Режим потенциалы [результат]

  VOLTMETERS_02V = 'VOLTMETERS_02V', // Режим вольтметры [0.2В]
  VOLTMETERS_2V = 'VOLTMETERS_2V', // Режим вольтметры [2В]
  VOLTMETERS_10V = 'VOLTMETERS_10V', // Режим вольтметры [10В]
  VOLTMETERS_100V = 'VOLTMETERS_100V', // Режим вольтметры [100В]

  VOLTMETERS_10V_RESULT = 'VOLTMETERS_10V_RESULT', // Режим вольтметры, 10В [результат]
}

export type IPP1Clips<T> = {
  yellow: T;
  red: T;
  black: T;
};

export type IPP1ClipsModel = IPP1Clips<IPP1SingleClipModel>;
export type IPP1ClipsConnectHelpers = IPP1Clips<TransformNode>;

export enum IPP1ClipConnectType {
  KIP_TERMINAL,
  EMCVE_TERMINAL,
  PLAYER_HAND,
  TOOL,
}

export type IPP1ClipConnectTargetId = {
  type: IPP1ClipConnectType;
  num: number;
};

export type IPP1ClipConnectTarget = {
  id: IPP1ClipConnectTargetId;
  bbox: AbstractMesh;
  helper: TransformNode;
};
