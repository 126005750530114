import { action, makeAutoObservable } from 'mobx';

import { AbstractMesh, TransformNode } from '@babylonjs/core';

import { HandState } from '../types';
import { MainHandsStore } from './mainHand';

export interface ISingleHandStore {
  /**
   * Индекс руки
   */
  get id(): number;

  /**
   * Видемость объекта
   */
  get isVisible(): boolean;

  /**
   * Видна ли перчатка
   */
  get isGloveVisible(): boolean;

  /**
   * helper-нода, за которую можно привязать инструмент
   */
  get helperTake(): TransformNode;

  /**
   * bbox руки
   */
  get bbox(): AbstractMesh;

  /**
   * Является ли рука правой
   */
  get isRightHand(): boolean;

  /**
   * Является ли рука левой
   */
  get isLeftHand(): boolean;

  /**
   * Является ли рука главной
   */
  get isMainHand(): boolean;

  /**
   * Анимируется ли сейчас модель
   */
  get animating(): boolean;

  /**
   * Текущее состояние руки
   */
  get state(): HandState;

  /**
   * Целевое состояние руки
   */
  get targetState(): HandState;
}

export class SingleHandStore implements ISingleHandStore {
  private _id: number;
  private _isVisible = false;
  private _isGloveVisible = false;

  private _root: TransformNode;
  private _helperTake: TransformNode;
  private _bbox: AbstractMesh;

  private _isRight: boolean;
  private _mainHand: MainHandsStore;
  private _state: HandState = HandState.FIXED;
  private _targetState: HandState = HandState.FIXED;

  public get id(): number {
    return this._id;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get isGloveVisible(): boolean {
    return this._isGloveVisible;
  }

  /**
   * Корень, за который можно привязать руку
   */
  public get root(): TransformNode {
    return this._root;
  }

  public get helperTake(): TransformNode {
    return this._helperTake;
  }

  public get bbox(): AbstractMesh {
    return this._bbox;
  }

  public get isRightHand(): boolean {
    return this._isRight;
  }

  public get isLeftHand(): boolean {
    return !this._isRight;
  }

  public get isMainHand(): boolean {
    return this._isRight
      ? this._mainHand.rightHandIsMain
      : this._mainHand.leftHandIsMain;
  }

  public get animating(): boolean {
    return this._state !== this._targetState;
  }

  public get state(): HandState {
    return this._state;
  }

  public get targetState(): HandState {
    return this._targetState;
  }

  constructor(
    id: number,
    root: TransformNode,
    helperTake: TransformNode,
    bbox: AbstractMesh,
    mainHand: MainHandsStore,
    isRight: boolean
  ) {
    this._id = id;
    this._root = root;
    this._helperTake = helperTake;
    this._bbox = bbox;
    this._isRight = isRight;
    this._mainHand = mainHand;

    makeAutoObservable(this, {
      setVisibility: action,
      setGloveVisibility: action,
      animationComplete: action,
      setTargetState: action,
    });
  }

  /**
   * Установить видемость руки
   * @param isVisible видна ли рука
   */
  public setVisibility(isVisible: boolean): void {
    this._isVisible = isVisible;
  }

  /**
   * Установить видемость перчатки
   * @param isVisible видна ли перчатка
   */
  public setGloveVisibility(isVisible: boolean): void {
    this._isGloveVisible = isVisible;
  }

  /**
   * Анимация завершена
   */
  public animationComplete(): void {
    this._state = this._targetState;
  }

  /**
   * Установить целевое состояние руки
   * @param state целевое состояние руки
   */
  public setTargetState(state: HandState): void {
    this._targetState = state;
  }

  /**
   * Выбрать эту руку в качестве главной
   */
  public setHandAsMain(): void {
    this._mainHand.setMainHand(this._isRight);
  }
}
