import { Scene, Vector3 } from '@babylonjs/core';

import { BaseModelObject } from '../../../common/base';

import { HandsConfig } from '../types';
import { HandsModel } from '../model';
import { HandsStore } from '../store';
import { SingleHandController } from './hand';
import { setupLogic } from '../logic';

export class HandsObject extends BaseModelObject<
  HandsModel,
  HandsStore,
  HandsConfig
> {
  private _left: SingleHandController;
  private _right: SingleHandController;

  public get left(): SingleHandController {
    return this._left;
  }

  public get right(): SingleHandController {
    return this._right;
  }

  constructor(
    scene: Scene,
    model: HandsModel,
    store: HandsStore,
    cfg: HandsConfig
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._left = new SingleHandController(scene, model.left, store.left);
    this._right = new SingleHandController(scene, model.right, store.right);
    this.registerController(this._left);
    this.registerController(this._right);
  }

  protected _connectToStore(store: HandsStore, cfg: HandsConfig): void {
    this.model.left.root.position = new Vector3(-1.0, 0.6, 0.2);
    this.model.right.root.position = new Vector3(-1.0, 0.6, -0.2);
  }

  /**
   * Скачать все модели и создать объект
   */
  public static async setup(
    scene: Scene,
    cfg: HandsConfig
  ): Promise<HandsObject> {
    const model = await HandsModel.load(cfg, scene);
    const store = new HandsStore(
      model.left.root,
      model.left.helperTake,
      model.left.bbox,
      model.right.root,
      model.right.helperTake,
      model.right.bbox
    );
    return new HandsObject(scene, model, store, cfg);
  }
}
