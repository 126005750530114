import { autorun } from 'mobx';

import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { loadJSImage } from '../../common/utils';

import { TabletConfig } from './types';
import { TabletModel } from './model';
import { TabletSheetGUI } from './gui';
import { TabletStore } from './store';
import { setupLogic } from './logic';

export class TabletObject extends BaseModelObject<
  TabletModel,
  TabletStore,
  TabletConfig
> {
  private static TEX_Q = 3;

  private _texture: AdvancedDynamicTexture;
  private _gui: TabletSheetGUI;

  /**
   * Вызывается когда кнопка 'Записать значения' нажата
   */
  private onAddButtonClick = new Observable<Vector2WithInfo>();

  constructor(
    scene: Scene,
    model: TabletModel,
    store: TabletStore,
    cfg: TabletConfig,
    UIImages: HTMLImageElement[]
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._texture = new AdvancedDynamicTexture(
      'Tablet',
      297 * TabletObject.TEX_Q,
      210 * TabletObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._texture.background = 'white';
    this._gui = new TabletSheetGUI(this._texture, TabletObject.TEX_Q, UIImages);

    // Настройка материала листа планшета
    const sheetMat = new StandardMaterial('TabletSheet_material', scene);
    sheetMat.backFaceCulling = false;
    sheetMat.specularColor = Color3.Black();
    sheetMat.diffuseTexture = this._texture;
    this.model.sheetMesh.material = sheetMat;
    this._texture.attachToMesh(this.model.sheetMesh, true);

    // Перенаправление callback'ов
    this._gui.onAddButtonClickCallback = (e: Vector2WithInfo) => {
      this.onAddButtonClick.notifyObservers(e);
    };
  }

  protected _connectToStore(store: TabletStore, cfg: TabletConfig): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
      this._gui.isEnabled = store.isEnabled;
    });
    autorun(() => {
      this._gui.setTabletContent(store.recordsId);
    });

    // Действие при нажатии на кнопку "Записать значения"
    this.onAddButtonClick.add(() => {
      store.addRecord();
    });
  }

  /**
   * Скачать все модели и создать планшет
   */
  public static async setup(
    scene: Scene,
    cfg: TabletConfig
  ): Promise<TabletObject> {
    const imagesPromise: Promise<HTMLImageElement>[] = [];

    for (let i = 0; i < 10; i += 1)
      imagesPromise.push(loadJSImage(`${cfg.ui.images_root_url}${i}.png`));

    const [model, UIImages] = await Promise.all([
      TabletModel.load(scene, cfg),
      Promise.all(imagesPromise),
    ]);
    const store = new TabletStore(model.root);
    return new TabletObject(scene, model, store, cfg, UIImages);
  }
}
