import {
  AbstractMesh,
  ISceneLoaderAsyncResult,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { findByName, loadMesh } from '../../common/utils';

import { EMCVEConfig } from './types';

export type EMCVETerminalModel = {
  name: string;
  body: AbstractMesh;
  bbox: AbstractMesh;
  helper: TransformNode;
};

export interface IEMCVEModel {
  get terminals(): EMCVETerminalModel[];
}

export class EMCVEModel implements IEMCVEModel {
  private _allMeshes: AbstractMesh[];
  private _root: TransformNode;
  private _terminals: EMCVETerminalModel[];

  public get root(): TransformNode {
    return this._root;
  }

  public get terminals(): EMCVETerminalModel[] {
    return this._terminals;
  }

  constructor(scene: Scene, model: ISceneLoaderAsyncResult) {
    const { meshes, transformNodes } = model;

    this._allMeshes = model.meshes;
    this._root = findByName(model.transformNodes, 'EMCVE');

    this._terminals = [
      {
        name: 'Terminal_EMCVE_ES',
        body: findByName(meshes, 'Terminal_EMCVE_ES'),
        bbox: findByName(meshes, 'Terminal_EMCVE_ES'),
        helper: findByName(transformNodes, 'Helper_Terminal_EMCVE_ES'),
      },
      {
        name: 'Terminal_EMCVE_VE',
        body: findByName(meshes, 'Terminal_EMCVE_VE'),
        bbox: findByName(meshes, 'Terminal_EMCVE_VE'),
        helper: findByName(transformNodes, 'Helper_Terminal_EMCVE_VE'),
      },
    ];
  }

  static async load(scene: Scene, cfg: EMCVEConfig): Promise<EMCVEModel> {
    const res = await loadMesh(scene, cfg.model);
    return new EMCVEModel(scene, res);
  }

  setVisibility(isVisible: boolean): void {
    for (const m of this._allMeshes) m.isVisible = isVisible;
  }
}
