import {
  AbstractMesh,
  Material,
  Nullable,
  Scene,
  TransformNode,
} from '@babylonjs/core';

import { CommonPlaneModel } from '../../../common/models';

import { AcceptJumpersWindowConfig } from '../types';

export class AcceptJumpersWindowModel {
  public static WIDTH = 180;
  public static HEIGHT = 120;
  public static REAL_SCALE = 0.135 / 180;

  private _plane: CommonPlaneModel;

  get root(): TransformNode {
    return this._plane.root;
  }

  get mesh(): AbstractMesh {
    return this._plane.plane;
  }

  get material(): Nullable<Material> {
    return this._plane.plane.material;
  }

  set material(material: Nullable<Material>) {
    this._plane.plane.material = material;
  }

  constructor(scene: Scene, plane: CommonPlaneModel) {
    this._plane = plane;
  }

  static async load(
    scene: Scene,
    cfg: AcceptJumpersWindowConfig
  ): Promise<AcceptJumpersWindowModel> {
    const plane = await CommonPlaneModel.load(scene, {
      width:
        AcceptJumpersWindowModel.WIDTH * AcceptJumpersWindowModel.REAL_SCALE,
      height:
        AcceptJumpersWindowModel.HEIGHT * AcceptJumpersWindowModel.REAL_SCALE,
    });

    return new AcceptJumpersWindowModel(scene, plane);
  }

  public setVisibility(isVisible: boolean): void {
    this._plane.setVisibility(isVisible);
  }
}
