import { Scene, Space, Vector3 } from '@babylonjs/core';

import { LessonScene } from 'features/lesson-page/scene-components/init';

import { BaseObject } from '../../common/base';

import { AcceptJumpersWindowObject } from '../accept-jumpers';
import { EMCVEObject } from '../EMCVE';
import { InfoPanelObject } from '../info-panel';
import { JumpersObject, JumpersStore } from '../jumpers';
import { KIPObject, KIPStore } from '../kip';
import { ResultPanelObject, ResultPanelStore } from '../result-panel';
import { ToolsPanelObject } from '../tools-panel';

import { SceneConfig } from './types';
import { SceneStore, SceneStoreList } from './store';
import {
  setupLogicForAcceptJumpersWindow,
  setupLogicForSceneJumpers,
  setupLogicForSceneKIP,
  setupLogicForSceneResultPanel,
} from './logic';

type SceneObjectList = {
  acceptJumpersWindow?: AcceptJumpersWindowObject;
  EMCVE?: EMCVEObject;
  infoPanel?: InfoPanelObject;
  jumpers?: JumpersObject;
  KIP?: KIPObject;
  resultPanel?: ResultPanelObject;
  toolsPanel?: ToolsPanelObject;
};

export class SceneObject extends BaseObject<SceneStore, SceneConfig> {
  private _objects: SceneObjectList;

  public get acceptJumpersWindow(): AcceptJumpersWindowObject | undefined {
    return this._objects.acceptJumpersWindow;
  }

  public get EMCVE(): EMCVEObject | undefined {
    return this._objects.EMCVE;
  }

  public get infoPanel(): InfoPanelObject | undefined {
    return this._objects.infoPanel;
  }

  public get jumpers(): JumpersObject | undefined {
    return this._objects.jumpers;
  }

  public get KIP(): KIPObject | undefined {
    return this._objects.KIP;
  }

  public get resultPanel(): ResultPanelObject | undefined {
    return this._objects.resultPanel;
  }

  public get toolsPanel(): ToolsPanelObject | undefined {
    return this._objects.toolsPanel;
  }

  constructor(
    scene: Scene,
    objects: SceneObjectList,
    store: SceneStore,
    cfg: SceneConfig
  ) {
    super(scene, store, cfg);
    objects.acceptJumpersWindow &&
      this.registerObject(objects.acceptJumpersWindow);
    objects.EMCVE && this.registerObject(objects.EMCVE);
    objects.infoPanel && this.registerObject(objects.infoPanel);
    objects.toolsPanel && this.registerObject(objects.toolsPanel);
    this._objects = objects;
  }

  public static async setup(
    scene: Scene,
    cfg: SceneConfig
  ): Promise<SceneObject> {
    const none = Promise.resolve(undefined);

    const loadedObjects = Promise.all([
      cfg.acceptJumpersWindow
        ? AcceptJumpersWindowObject.setup(scene, cfg.acceptJumpersWindow)
        : none,
      cfg.EMCVE ? EMCVEObject.setup(scene, cfg.EMCVE) : none,
      cfg.infoPanel ? InfoPanelObject.setup(scene, cfg.infoPanel) : none,
      cfg.jumpers ? JumpersObject.setup(scene, cfg.jumpers) : none,
      cfg.KIP ? KIPObject.setup(scene, cfg.KIP) : none,
      cfg.resultPanel ? ResultPanelObject.setup(scene, cfg.resultPanel) : none,
      cfg.toolsPanel ? ToolsPanelObject.setup(scene, cfg.toolsPanel) : none,
    ]);

    // Build objects
    const objects: SceneObjectList = {};
    [
      objects.acceptJumpersWindow,
      objects.EMCVE,
      objects.infoPanel,
      objects.jumpers,
      objects.KIP,
      objects.resultPanel,
      objects.toolsPanel,
    ] = await loadedObjects;

    // Build stores
    const stores: SceneStoreList = {};
    stores.acceptJumpersWindow = objects.acceptJumpersWindow?.store;
    stores.EMCVE = objects.EMCVE?.store;
    stores.infoPanel = objects.infoPanel?.store;
    stores.toolsPanel = objects.toolsPanel?.store;

    // for legacy support
    stores.jumpers = objects.jumpers ? new JumpersStore() : undefined;
    stores.KIP = objects.KIP ? new KIPStore() : undefined;
    stores.resultPanel = objects.resultPanel
      ? new ResultPanelStore()
      : undefined;

    return new SceneObject(scene, objects, new SceneStore(stores), cfg);
  }

  // TODO: исключительно для поддержания legacy кода
  public connectToStore2(scene: LessonScene): void {
    scene.scene?.KIP?.connectToStore(this.store.KIP!);
    scene.scene?.jumpers?.connectToStore(this.store.jumpers!);
    scene.scene?.resultPanel?.connectToStore(this.store.resultPanel!);
  }

  // TODO: исключительно для поддержания legacy кода
  // eslint-disable-next-line class-methods-use-this
  public setupLogic2(scene: LessonScene): void {
    if (scene.scene?.acceptJumpersWindow) {
      const { root } = scene.scene.acceptJumpersWindow.model;
      root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
      root.position.set(-0.12, 0.9, 0);

      const imgRoot = scene.scene.acceptJumpersWindow.imgModel.root;
      imgRoot.parent = root;
      imgRoot.position.set(0, 0.16, 0);
    }

    if (scene.scene?.KIP) {
      const { model } = scene.scene.KIP;
      model.main.checkCollisions = true;
      model.root.position = new Vector3(0, 0, 0);
    }

    if (scene.scene?.resultPanel && scene.scene.KIP) {
      const { root } = scene.scene.resultPanel.model;
      root.parent = scene.scene.KIP.model.root;
      root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
      root.position.set(0, 0.7, -0.5);
    }

    setupLogicForAcceptJumpersWindow(scene);
    setupLogicForSceneKIP(scene);
    setupLogicForSceneJumpers(scene);
    setupLogicForSceneResultPanel(scene);
  }
}
