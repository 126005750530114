import { autorun } from 'mobx';

import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  Space,
  StandardMaterial,
  Texture,
  Vector3,
} from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';
import { loadJSImage } from '../../common/utils';

import {
  PanelItemType,
  ToolsPanelClickInfo,
  ToolsPanelConfig,
  ToolsPanelPlaceType,
} from './types';
import { ToolsPanelGUI } from './gui';
import { ToolsPanelModel } from './model';
import { ToolsPanelStore } from './store';
import { setupLogic } from './logic';

export class ToolsPanelObject extends BaseModelObject<
  ToolsPanelModel,
  ToolsPanelStore,
  ToolsPanelConfig
> {
  private static WIDTH = 240;
  private static HEIGHT = 180;
  private static TEX_Q = 4;
  private static REAL_SCALE = 0.8 / 240;

  private _model: ToolsPanelModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: ToolsPanelGUI;

  private onToolsClick = new Observable<ToolsPanelClickInfo>();
  private onCloseButtonClick = new Observable<Vector2WithInfo>();

  constructor(
    scene: Scene,
    model: ToolsPanelModel,
    store: ToolsPanelStore,
    cfg: ToolsPanelConfig,
    UIImages: Record<PanelItemType, HTMLImageElement>
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._model = model;
    this._texture = new AdvancedDynamicTexture(
      'ToolsPanel',
      ToolsPanelObject.WIDTH * ToolsPanelObject.TEX_Q,
      ToolsPanelObject.HEIGHT * ToolsPanelObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new ToolsPanelGUI(
      this._texture,
      ToolsPanelObject.TEX_Q,
      UIImages
    );

    // Настройка материала
    const modalMat = new StandardMaterial('TabletModal_material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this._texture;
    modalMat.opacityTexture = this._texture;
    this.model.plane.material = modalMat;
    this._texture.attachToMesh(this._model.plane, true);

    // Перенаправление callback'ов
    this._gui.onToolsClick = (e) => {
      this.onToolsClick.notifyObservers(e);
    };
    this._gui.onCloseButtonClick = (e) => {
      this.onCloseButtonClick.notifyObservers(e);
    };
  }

  public static async setup(
    scene: Scene,
    cfg: ToolsPanelConfig
  ): Promise<ToolsPanelObject> {
    const imagesPromise: Promise<HTMLImageElement>[] = [
      loadJSImage(`${cfg.ui.images_root_url}brush.png`),
      loadJSImage(`${cfg.ui.images_root_url}gloves.png`),
      loadJSImage(`${cfg.ui.images_root_url}ipp1.png`),
      loadJSImage(`${cfg.ui.images_root_url}key.png`),
      loadJSImage(`${cfg.ui.images_root_url}tablet.png`),
      loadJSImage(`${cfg.ui.images_root_url}tube.png`),
      loadJSImage(`${cfg.ui.images_root_url}wrench.png`),
    ];

    const modelPromise = await ToolsPanelModel.load(scene, {
      height: ToolsPanelObject.HEIGHT * ToolsPanelObject.REAL_SCALE,
      width: ToolsPanelObject.WIDTH * ToolsPanelObject.REAL_SCALE,
    });

    const [model, images] = await Promise.all([
      modelPromise,
      Promise.all(imagesPromise),
    ]);

    const UIImages: Record<PanelItemType, HTMLImageElement> = {
      [PanelItemType.BRUSH]: images[0],
      [PanelItemType.GLOVES]: images[1],
      [PanelItemType.IPP1]: images[2],
      [PanelItemType.KEY]: images[3],
      [PanelItemType.TABLET]: images[4],
      [PanelItemType.TUBE]: images[5],
      [PanelItemType.WRENCH]: images[6],
    };

    const store = new ToolsPanelStore(model);
    return new ToolsPanelObject(scene, model, store, cfg, UIImages);
  }

  protected _connectToStore(store: ToolsPanelStore): void {
    autorun(() => {
      this._model.setVisibility(store.isVisible);
    });
    autorun(() => {
      this._gui.setSelected(store.selectedItems);
    });

    this.onToolsClick.add((e) => {
      store.clickToolButton(e.item);
    });

    autorun(() => {
      const { place } = store;
      if (place === undefined) return;

      const { root } = this.model;
      root.parent = place.parent;

      switch (place.type) {
        case ToolsPanelPlaceType.KIP_RIGHT: {
          root.rotate(new Vector3(0, 1, 0), Math.PI / 2, Space.LOCAL);
          root.rotate(new Vector3(0, 1, 0), -0.5, Space.LOCAL);
          root.position.set(0.2, 0.8, -0.5);
          break;
        }
      }
    });
  }
}
