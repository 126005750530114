import { autorun } from 'mobx';

import { AdvancedDynamicTexture, Vector2WithInfo } from '@babylonjs/gui';
import {
  Color3,
  Observable,
  Scene,
  StandardMaterial,
  Texture,
} from '@babylonjs/core';

import { BaseModelObject } from '../../common/base';

import { AcceptJumpersImgWindowModel, AcceptJumpersWindowModel } from './model';
import { AcceptJumpersWindowConfig } from './types';
import { AcceptJumpersWindowGUI } from './gui';
import { AcceptJumpersWindowStore } from './store';

export class AcceptJumpersWindowObject extends BaseModelObject<
  AcceptJumpersWindowModel,
  AcceptJumpersWindowStore,
  AcceptJumpersWindowConfig
> {
  private static TEX_Q = 3;

  private _imgModel: AcceptJumpersImgWindowModel;
  private _texture: AdvancedDynamicTexture;
  private _gui: AcceptJumpersWindowGUI;

  public onCloseButtonClick = new Observable<Vector2WithInfo>();
  public onAcceptButtonClick = new Observable<Vector2WithInfo>();
  public onRejectButtonClick = new Observable<Vector2WithInfo>();

  get imgModel(): AcceptJumpersImgWindowModel {
    return this._imgModel;
  }

  constructor(
    scene: Scene,
    model: AcceptJumpersWindowModel,
    store: AcceptJumpersWindowStore,
    cfg: AcceptJumpersWindowConfig,
    imgModel: AcceptJumpersImgWindowModel
  ) {
    super(scene, model, store, cfg);
    this._imgModel = imgModel;
    this._texture = new AdvancedDynamicTexture(
      'AcceptJumpersWindow',
      AcceptJumpersWindowModel.WIDTH * AcceptJumpersWindowObject.TEX_Q,
      AcceptJumpersWindowModel.HEIGHT * AcceptJumpersWindowObject.TEX_Q,
      scene,
      false,
      Texture.TRILINEAR_SAMPLINGMODE,
      true
    );
    this._gui = new AcceptJumpersWindowGUI(this._texture);
    this._gui.scale = AcceptJumpersWindowObject.TEX_Q;

    // Настройка материала окна
    const modalMat = new StandardMaterial('TabletModal_material', scene);
    modalMat.backFaceCulling = false;
    modalMat.diffuseColor = Color3.Black();
    modalMat.specularColor = Color3.Black();
    modalMat.emissiveTexture = this._texture;
    modalMat.opacityTexture = this._texture;
    this.model.material = modalMat;
    this._texture.attachToMesh(this.model.mesh, true);

    // Перенаправление callback'ов
    this._gui.onCloseButtonClickCallback.add((e: Vector2WithInfo) => {
      this.onCloseButtonClick.notifyObservers(e);
    });
    this._gui.onAcceptButtonClickCallback.add((e: Vector2WithInfo) => {
      this.onAcceptButtonClick.notifyObservers(e);
    });
    this._gui.onRejectButtonClickCallback.add((e: Vector2WithInfo) => {
      this.onRejectButtonClick.notifyObservers(e);
    });
  }

  public static async setup(
    scene: Scene,
    cfg: AcceptJumpersWindowConfig
  ): Promise<AcceptJumpersWindowObject> {
    const model = await AcceptJumpersWindowModel.load(scene, cfg);
    const imgModel = await AcceptJumpersImgWindowModel.load(scene, cfg);
    const store = new AcceptJumpersWindowStore();
    return new AcceptJumpersWindowObject(scene, model, store, cfg, imgModel);
  }

  protected _connectToStore(
    store: AcceptJumpersWindowStore,
    cfg: AcceptJumpersWindowConfig
  ): void {
    autorun(() => {
      this.model.setVisibility(store.isVisible);
      this._imgModel.setVisibility(store.isVisible);
    });
  }
}
