import { autorun } from 'mobx';

import { HighlightLayer, Scene } from '@babylonjs/core';

import { BaseModelObject } from '../../../common/base';
import { loadJSImage } from '../../../common/utils';

import { ClipsController } from './clips';
import { IPP1ButtonsController } from './buttons';
import { IPP1Config } from '../types';
import { IPP1DisplayController } from './display';
import { IPP1Model } from '../model';
import { IPP1Store } from '../store';
import { setupLogic } from '../logic';

export class IPP1Object extends BaseModelObject<
  IPP1Model,
  IPP1Store,
  IPP1Config
> {
  private _clips: ClipsController;
  private _buttonsController: IPP1ButtonsController;
  private _displayController: IPP1DisplayController;
  private _highlightLayer: HighlightLayer;

  public get buttons(): IPP1ButtonsController {
    return this._buttonsController;
  }

  public get display(): IPP1DisplayController {
    return this._displayController;
  }

  public get clips(): ClipsController {
    return this._clips;
  }

  constructor(
    scene: Scene,
    model: IPP1Model,
    store: IPP1Store,
    cfg: IPP1Config,
    UIImages: HTMLImageElement[]
  ) {
    super(scene, model, store, cfg);
    this._setLogicFunc(setupLogic);

    this._highlightLayer = new HighlightLayer('IPP_hll', scene);
    this._clips = new ClipsController(
      scene,
      model.clips,
      store.clips,
      cfg,
      this._highlightLayer
    );
    this._buttonsController = new IPP1ButtonsController(
      scene,
      model,
      store.buttons,
      cfg
    );
    this._displayController = new IPP1DisplayController(
      scene,
      model,
      store.display,
      cfg,
      UIImages
    );

    this.registerController(this._clips);
    this.registerController(this._buttonsController);
    this.registerController(this._displayController);
  }

  protected _connectToStore(): void {
    autorun(() => {
      this.model.setVisibility(this.store.isVisible);
    });
  }

  public static async setup(
    scene: Scene,
    cfg: IPP1Config
  ): Promise<IPP1Object> {
    const imagesPromise: Promise<HTMLImageElement>[] = [];

    for (let i = 0; i < 12; i += 1)
      imagesPromise.push(loadJSImage(`${cfg.ui.images_root_url}${i}.png`));

    const [model, UIImages] = await Promise.all([
      IPP1Model.load(scene, cfg),
      Promise.all(imagesPromise),
    ]);

    const store = new IPP1Store(model);
    return new IPP1Object(scene, model, store, cfg, UIImages);
  }
}
