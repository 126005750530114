import { TransformNode } from '@babylonjs/core';
import { Vector2WithInfo } from '@babylonjs/gui';

export enum PanelItemType {
  GLOVES,
  KEY,
  BRUSH,
  WRENCH,
  TUBE,
  TABLET,
  IPP1,
}

export type ToolsPanelConfig = {
  ui: {
    images_root_url: string;
  };
  place: string;
};

export type ToolsPanelClickInfo = {
  item: PanelItemType;
  v: Vector2WithInfo;
};

export enum ToolsPanelPlaceType {
  KIP_RIGHT = 'kip_right',
}

export type ToolsPanelPlace = {
  parent: TransformNode;
  type: ToolsPanelPlaceType;
};
