/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AxiosResponse } from 'axios';
import {
  LessonController,
  LessonSession,
  LessonType,
  Script,
  ScriptStep,
} from 'api';
import { action, autorun, makeAutoObservable } from 'mobx';

import { RequiredState } from 'api/impl/web-vr-training';
import { ResultPanelStepsPageContentLineType } from '../scene-components/objects/scene/result-panel';

import { LessonExistenceType, SceneLoadingProgressType } from '../types';

import { fetchLearnerReport, isRequiredStatesFulfilled } from './utils';

import { LessonAudioHints } from '../scene-components/audio';
import { SceneLoadingScreen } from '../scene-components/objects/scene-loading-screen';

// eslint-disable-next-line import/no-cycle
import { Isignal, StreamInitiator } from '../scene-components/stream/stream';
import { LessonScene, LessonSceneBuilder } from '../scene-components/init';

import {
  CoreStore,
  IPlayerStore,
  SceneStore,
  ToolsStore,
  WorldStore,
} from '../scene-components/objects';
import { FullscreenPanelStore } from './fullscreen-panel';
import { ScriptStore } from './script';
import { buildConfigs } from './config';

export class LessonStore {
  socket: WebSocket | null = null;

  isInitialModalVisible = true;

  doesLessonExist: LessonExistenceType = LessonExistenceType.Unknown;

  lessonType: LessonType | null = null;

  lessonId = '';
  sessionId: string | null = null;
  lessonStartTime = '';
  lessonFinishTime = '';

  isLessonTaken: boolean | null = null;
  isExamResultsPanelVisible = false;

  canvasElement: null | HTMLCanvasElement = null;

  audioHints: LessonAudioHints | null = null;

  unfulfilledRequiredStates: RequiredState[] = [];

  // TODO: Используется исключительно для работы сценария. Вероятно в будущем нужно будет переделать
  sceneState: Record<string, unknown>;

  sceneLoadingScreen: SceneLoadingScreen;
  sceneLoadingScreenProgress: SceneLoadingProgressType;

  broadcaster?: StreamInitiator;

  // TODO: Это не часть стора и во время рефакторинг необходимо вынести
  lessonScene: LessonScene | null = null;

  // *********** Отрефакторенная часть стора *********** //
  private _isVR = false;

  private _core?: CoreStore;
  private _fullscreenPanel = new FullscreenPanelStore();
  private _player?: IPlayerStore;
  private _scene?: SceneStore;
  private _script = new ScriptStore();
  private _tools?: ToolsStore;
  private _world?: WorldStore;

  public get isVR(): boolean {
    return this._isVR;
  }

  public get core(): CoreStore {
    return this._core!;
  }

  public get fullscreenPanel(): FullscreenPanelStore {
    return this._fullscreenPanel;
  }

  public get player(): IPlayerStore {
    return this._player!;
  }

  public get tools(): ToolsStore {
    return this._tools!;
  }

  public get scene(): SceneStore {
    return this._scene!;
  }

  public get script(): ScriptStore {
    return this._script;
  }

  public get world(): WorldStore {
    return this._world!;
  }

  public setIsVR(isVR: boolean): void {
    this._isVR = isVR;
  }
  // *************************************************** //

  constructor() {
    makeAutoObservable(
      this,
      {
        setIsVR: action,
      },
      { autoBind: true }
    );

    this.sceneState = {
      areAllLabelsTouched: false,
      isKipJumpersPositionCorrect: null,
      isKipTerminalPadClean: false,
      areKipTerminalsLoose: '[]',
      areAllKipTerminalsLoose: false,
      areAllKipTerminalsTight: true,
      areKipTerminalsGreased: false,
      isJumperT1VEDisconnected: false,
      isJumperP1T3Disconnected: false,
      ipp1Probes: '',
      ipp1Mode: '',
      isGlovesOn: false,
      isFrontDoorOpen: false,
      isBackDoorOpen: false,
      isMultimeterTaken: false,
      isTabletTaken: false,
      connectedTerminals: [],
      isTerminalsConnected: false,
      tabletEntriesCount: 0,
      correctlyAnsweredTestQuestion: true,
      areAllWiresConnected: true,
    };

    this.sceneLoadingScreen = new SceneLoadingScreen(
      '',
      this.setSceneLoadingProgress
    );

    this.sceneLoadingScreenProgress = SceneLoadingProgressType.NotLoaded;

    autorun(() => {
      this.tryToMoveToNextScriptStep();
    });

    autorun(() => {
      if (
        this.audioHints &&
        this.script.currentStep &&
        this.sceneLoadingScreenProgress === SceneLoadingProgressType.Loaded
      ) {
        this.audioHints?.playAudioHintForStep(
          this.script.currentStep.stepNumber!
        );
      }
    });

    autorun(() => {
      if (
        this.canvasElement &&
        this.script.hasScript &&
        this.doesLessonExist === LessonExistenceType.Available &&
        this.isLessonTaken === false
      ) {
        this.initScene();
      }
    });

    autorun(() => {
      this.setIsVR(this.core?.xr?.isActive || false);
    });
  }

  setSceneLoadingProgress(sceneProgress: SceneLoadingProgressType): void {
    this.sceneLoadingScreenProgress = sceneProgress;
  }

  // TODO: Вынести в ScriptStore
  tryToMoveToNextScriptStep(): void {
    if (!this.script.hasScript || !this.script.currentStep?.doneCondition)
      return;
    const { stateName, stateValue } = this.script.currentStep.doneCondition;

    // Если doneCondition не выполняется — просто ничего не делаем
    if (this.sceneState[stateName!] !== stateValue) return;

    console.log(
      `Шаг ${this.script.currentStepNo}. doneCondition выполнено: состояние ${stateName} изменилось на ${stateValue}`
    );

    if (
      !isRequiredStatesFulfilled(
        this.script.currentStep.requiredStates!,
        this.sceneState
      )
    ) {
      console.log('! Не все requiredStates выполнены.');
      this.sendActiveStepInfoViaSocket(false);
      this.handleUserMistakes();
      return;
    }
    // сначала отправить инфу о завершенном шаге на сервер, потом перейти к следующему шагу
    console.log('requiredStates выполнены.');
    this.sendActiveStepInfoViaSocket(true);
    this.script.gotoNextStep();
    this.unfulfilledRequiredStates = [];
    if (this.script.isScriptPassed) {
      console.log('Сценарий пройден успешно');
      this.finishLesson();
    } else {
      console.log(
        `Теперь следим за изменением состояния ${this.script.currentStep.doneCondition.stateName} (шаг ${this.script.currentStepNo})`
      );
    }
  }

  handleUserMistakes(): void {
    if (this.script.currentStep!.requiredStates) {
      switch (this.lessonType) {
        case LessonType.Learning:
          this.unfulfilledRequiredStates =
            this.script.currentStep!.requiredStates.filter(
              (state: RequiredState) => !this.sceneState[state.stateName!]
            );
          return;
        case LessonType.Training:
          // nothing, they should understand themselves
          return;
        case LessonType.Exam:
          this.finishExamWithFailure();
      }
    }
  }

  setupPeer(adminId: string): void {
    if (!this.canvasElement) return;
    const cb = (data: Isignal): void => {
      const learnerSignal = {
        type: 'learnerPeer',
        message: {
          adminId,
          data: {
            signal: data,
          },
        },
      };
      this.socket?.send(JSON.stringify(learnerSignal));
    };
    this.broadcaster = new StreamInitiator(adminId, this.canvasElement, cb);
  }

  subscribeByLearner(lessonId: string, lessonType: LessonType): void {
    const createSocketHost = (): string => {
      const { protocol, hostname, port } = window.location;
      return `${protocol === 'https:' ? 'wss' : 'ws'}://${hostname}:${port}`;
    };
    this.socket = new WebSocket(
      `${createSocketHost()}${
        process.env.REACT_APP_SERVICE_WS
      }/events/learner?lessonId=${lessonId}&lessonType=${lessonType}`
    );
    this.socket.onopen = () => this.socket?.send('Oh! Hello Mr. Server');

    this.socket.onmessage = ({ data }) => {
      const { type, message } = JSON.parse(data);
      switch (type) {
        case 'learnerAlreadyConnected':
          // ToDo: запрещать сессию, ибо урок уже начат
          console.warn('Learner already connected!');
          this.isLessonTaken = true;
          this.doesLessonExist = LessonExistenceType.Taken;
          break;
        case 'lessonSessionInfo':
          this.isLessonTaken = false;
          this.sessionId = message?.id || null;
          break;
        case 'startStream':
          this.setupPeer(message.adminId);
          break;
        case 'adminSignal':
          this.broadcaster?.sendSignal(message.data.signal);
          break;
        default:
          console.warn(`Unhandled event type from websocket: ${type}`);
      }
    };
    this.socket.onclose = () => console.log('subscribeByLearner closed');
  }

  sendActiveStepInfoViaSocket(isCompleted: boolean): void {
    const activeStepInfoMessage = {
      type: 'stepCompleted',
      message: {
        step: `${this.script.currentStep!.id}`,
        status: isCompleted,
      },
    };
    this.socket?.send(JSON.stringify(activeStepInfoMessage));
  }

  unsubscribeByLearner(): void {
    this.socket?.close();
  }

  setLessonId(value: string): void {
    this.lessonId = value;
  }

  setLessonType(value: LessonType): void {
    this.lessonType = value;
    this.lessonStartTime = new Date().toLocaleString();
    this.isInitialModalVisible = false;
  }

  // TODO: Вынести в ScriptStore
  async fetchLessonScript(lessonId: string): Promise<void> {
    try {
      this.setLessonId(lessonId);

      const { data }: AxiosResponse<Script> =
        await LessonController.apiLessonIdScriptGet(lessonId);

      this.doesLessonExist = LessonExistenceType.Available;
      console.log(data);

      data.steps?.sort(
        (el1: ScriptStep, el2: ScriptStep) => el1.stepNumber! - el2.stepNumber!
      );

      this.script.setScript(data);
    } catch (error) {
      this.doesLessonExist = LessonExistenceType.Unavailable;
    }
  }

  collectAllAudioHintsFromScript(): void {
    if (!this.script.hasScript) return;

    this.audioHints = new LessonAudioHints(this.script.allAudioHints);
    this.audioHints.loadAllAudioHints();
  }

  async finishLesson(): Promise<void> {
    if (this.lessonFinishTime) return;

    try {
      this.lessonFinishTime = new Date().toLocaleString();
      // не понятно, как Axios может вернуть что-то типа response.isOK
      const { status } = await LessonSession.apiLessonsessionIdFinishPost(
        this.sessionId!
      );
      if (status >= 200 && status < 300) {
        this.showUserResultsPanel();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  finishExamWithFailure(): void {
    // Todo: отобразить какой-нибудь знак на экране, что произошел фейл
    console.warn('This loser has made a mistake');
    this.finishLesson();
  }

  setCanvasElement(canvasElement: HTMLCanvasElement): void {
    this.canvasElement = canvasElement;
  }

  async showUserResultsPanel(): Promise<void> {
    const { resultPanel } = this.scene;
    if (!resultPanel) return;

    const userReport = await fetchLearnerReport(this.sessionId!);
    if (!userReport) {
      console.log("Couldn't get user's report");
      return;
    }
    resultPanel.setInfoContent({
      fullName: userReport.fullName,
      lessonTitle: userReport.scriptName!,
      lessonDuration: userReport.lessonDurationFormatted,
      lessonMode: userReport.lessonTypeFormatted,
    });

    const stepsContent: ResultPanelStepsPageContentLineType[] = [];
    userReport.stepsFormatted.forEach((step) => {
      stepsContent.push({
        name: step.name || '',
        errorCount: step.errorCountFormatted,
        stepDuration: step.stepDurationFormatted,
      });
    });

    resultPanel.setStepsContent(stepsContent);
    resultPanel.setVisibility(true);
  }

  async initScene(): Promise<void> {
    if (!this.canvasElement) return;

    const sceneBuilder = new LessonSceneBuilder(this.canvasElement, true);
    sceneBuilder.setLoadingScreen(this.sceneLoadingScreen);
    sceneBuilder.displayLoadingUI();

    // TODO: добавить чек, есть ли вообще в скрипте аудиоподсказки
    this.collectAllAudioHintsFromScript();

    // Создание и настройка сцены
    await sceneBuilder.load(buildConfigs);

    sceneBuilder.place();
    this.lessonScene = sceneBuilder.scene;
    this._core = this.lessonScene.core?.store;
    this._player = this.lessonScene.player?.store;
    this._scene = this.lessonScene.scene?.store;
    this._tools = this.lessonScene.tools?.store;
    this._world = this.lessonScene.world?.store;
    sceneBuilder.connectToStore(this);
    sceneBuilder.setupLogic(this);

    sceneBuilder.hideLoadingUI();
    this.lessonScene.runRenderLoop();
    // this.lessonScene.debugLayer.show();
  }
}

export const LessonStoreInstance = new LessonStore();
export type LessonStoreType = typeof LessonStoreInstance;
